import React from 'react';
import Img from 'gatsby-image';
/** @jsx jsx */
import { jsx, Styled, Box, Label, Input, Select, Textarea } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import Container from './Container';
import ContactForm from './ContactForm';

const HomepageForm = ({ headline, subHeading }) => {
  return (
    <div
      sx={{
        width: '100%',
        my: [2, 2, 2, 2, 4],
      }}
    >
      <Box
        sx={{
          py: 4,
          px: 4,
          width: ['90%', '90%', '90%', '90%', '40%'],
          mx: ['auto', 'auto', 'auto', 'auto', 0],
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          '@supports (backdrop-filter: blur(6px))': {
            backdropFilter: 'blur(6px)',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
        }}
      >
        <Styled.h1 as="h1" sx={{ mt: 0, mb: 3 }}>
          {headline}
        </Styled.h1>
        <Styled.h2
          as="p"
          sx={{ fontStyle: 'italic', color: 'primary', mb: 3, mt: 0 }}
        >
          {subHeading}
        </Styled.h2>
        <ContactForm />
      </Box>
    </div>
  );
};

export default HomepageForm;
