import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

/** @jsx jsx */
import { jsx, useThemeUI, Styled, Flex } from 'theme-ui';

import ServicePromoBox from './ServicePromoBox';
import Container from '../Container';

const ServicePromoBoxes = ({ data }) => {
  const renderPromoBoxes = () => {
    const boxes = [];
    data.forEach((boxData, key) => {
      //console.log('boxData', boxData);
      boxes.push(<ServicePromoBox key={key} data={boxData} />);
    });
    return boxes;
  };

  return (
    <div sx={{}}>
      <Container
        style={{
          py: 7,
        }}
      >
        <Styled.h1 as="h2">Funding Origination Services</Styled.h1>
        <div
          sx={{
            display: ['grid'],
            gridGap: 4,
            gridTemplateColumns: [
              '100%',
              '100%',
              '50% 50%',
              '33% 33% 33%',
              '25% 25% 25% 25%',
            ],
            justifyItems: 'center',
          }}
        >
          {renderPromoBoxes()}
        </div>
      </Container>
    </div>
  );
};

ServicePromoBoxes.propTypes = {};

export default ServicePromoBoxes;
