import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';

const ShortIconColumn = ({ column }) => {
  return (
    <div>
      <div
        sx={{
          width: '100%',
          minHeight: '175px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Img fixed={column.icon.asset.fixed} />
      </div>
      <Styled.h3 sx={{ color: 'primary' }}>{column.headline}</Styled.h3>
      <Styled.p>{column.stringContent}</Styled.p>
    </div>
  );
};

ShortIconColumn.propTypes = {
  column: PropTypes.shape({
    headline: PropTypes.string,
    icon: PropTypes.object,
    stringContent: PropTypes.string,
  }).isRequired,
};

export default ShortIconColumn;
