import React from 'react';
import { graphql } from 'gatsby';

/** @jsx jsx */
import { jsx, useThemeUI, Styled, Flex } from 'theme-ui';
import ShortIconColumn from './ShortIconColumn';
import PropTypes from 'prop-types';
import Container from '../Container';

const PromoColumns = ({ columns, columnType = 'shortIcon' }) => {
  const renderColumns = () => {
    const columnNodes = [];
    columns.forEach((column, key) => {
      columnNodes.push(<ShortIconColumn key={key} column={column} />);
    });
    return columnNodes;
  };

  return (
    <section
      sx={{
        backgroundColor: 'primaryShade',
      }}
    >
      <Container
        style={{
          py: 7,
          display: ['grid'],
          gridGap: 4,
          gridTemplateColumns: ['100%', '100%', '33% 33% 33%'],
        }}
      >
        {renderColumns()}
      </Container>
    </section>
  );
};

PromoColumns.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      icon: PropTypes.object,
      stringContent: PropTypes.string,
    })
  ).isRequired,
  columnType: PropTypes.string,
};

export default PromoColumns;
