import React from 'react';
import { graphql } from 'gatsby';

/** @jsx jsx */
import { jsx, useThemeUI, Styled } from 'theme-ui';
import navData from '../data/navData';
import Header from '../components/Header';
import Container from '../components/Container';
import Navigation from '../components/navigation';
import HomepageForm from '../components/HomepageForm';
import PromoColumns from '../components/promo-columns';
import ServicePromoBoxes from '../components/servicePromoBoxes';
import Footer from '../components/Footer';
import SEO from '../components/seo';

export default ({ data }) => {
  //console.log('homepage data', data);
  const metaImage = {
    src: data.hero.figure.image.asset.metaImage.src,
    height: 1200,
    width: 630,
  };
  const heroSrc = data.hero.figure.image.asset.fixed.src;
  const sellingPropositions = data.sellingPropositions.nodes;
  const servicePromoData = data.servicePromos.nodes;
  const headline = data.hero.headline;
  const subHeading = data.hero.subHeading;
  const theme = useThemeUI().theme;
  return (
    <main>
      <SEO title="Commercial Real Eastate Funding" metaImage={metaImage} />
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Header />
        <Navigation background={theme.colors.primary} navData={navData} />
        <div
          id="homepage-hero"
          sx={{
            flexGrow: 1,
            backgroundColor: 'muted',
            backgroundImage: `url("${heroSrc}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              height: '100%',
            }}
          >
            <Container>
              <HomepageForm headline={headline} subHeading={subHeading} />
            </Container>
          </div>
        </div>
      </div>
      <PromoColumns columns={sellingPropositions} columnType="shortIcon" />
      <ServicePromoBoxes data={servicePromoData} />
      <Footer />
    </main>
  );
};

export const query = graphql`
  query {
    hero: sanityPromoContent(slug: { current: { eq: "homepage-hero" } }) {
      headline
      subHeading
      figure {
        image {
          asset {
            fixed(width: 1500) {
              src
            }
            metaImage: fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
    sellingPropositions: allSanityPromoContent(
      filter: {
        tags: {
          elemMatch: { tag: { tag: { current: { eq: "homepage-usp" } } } }
        }
      }
      sort: { fields: tags___priority, order: ASC }
    ) {
      nodes {
        icon {
          asset {
            fixed(width: 150) {
              ...GatsbySanityImageFixed
            }
          }
        }
        headline
        stringContent
      }
    }
    servicePromos: allSanityService(sort: { fields: name, order: ASC }) {
      nodes {
        shortExcerpt
        figure {
          alt
          image {
            asset {
              fixed(width: 300) {
                src
              }
            }
          }
        }
        name
        slug {
          current
        }
      }
    }
  }
`;
