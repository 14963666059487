import React from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import gsap from 'gsap';

/** @jsx jsx */
import { jsx, useThemeUI, Styled, Flex } from 'theme-ui';
import ServicePromoBoxes from '.';

const ServicePromoBox = ({ data }) => {
  if (
    !data ||
    !data.figure ||
    !data.figure.image ||
    !data.figure.image.asset ||
    !data.slug ||
    !data.slug.current
  ) {
    return null;
  }
  const imgSrc = data.figure.image.asset.fixed.src;
  const { name } = data;
  const excerpt = data.shortExcerpt;
  const slug = data.slug.current;
  const primary = useThemeUI().theme.colors.primary;
  const text = useThemeUI().theme.colors.text;
  const imgAlt = data.figure.alt;

  const getPromoTarget = (target) => {
    if (!target.classList.contains('service-box')) {
      do {
        target = target.parentElement;
      } while (!target.classList.contains('service-box'));
    }
    return target;
  };
  const tl = gsap.timeline({ ease: 'back.out' });
  const clearTl = () => {
    if (tl.isActive()) {
      const lineDuration = tl.totalDuration();
      tl.seek(lineDuration, false);
    }
  };

  const onMouseEnter = (e) => {
    clearTl();
    const target = getPromoTarget(e.target);
    const img = target.getElementsByTagName('IMG')[0];
    const name = target.getElementsByClassName('service-box-name')[0];
    tl.to(img, { scale: 1.03, duration: 0.5 }).to(
      name,
      {
        color: primary,
        duration: 0.3,
      },
      '-=0.25'
    );
  };

  const onMouseLeave = (e) => {
    clearTl();
    const target = getPromoTarget(e.target);
    const img = target.getElementsByTagName('IMG')[0];
    const name = target.getElementsByClassName('service-box-name')[0];
    tl.to(img, { scale: 1, duration: 0.3 }).to(
      name,
      {
        color: text,
        duration: 0.3,
      },
      '-=0.25'
    );
  };

  const TitleBox = () => {
    return (
      <div sx={{ backgroundColor: 'accent', p: 2, textAlign: 'center' }}>
        <Styled.h3
          as="span"
          className="service-box-name"
          sx={{ color: 'text' }}
        >
          {name}
        </Styled.h3>
      </div>
    );
  };

  const FeaturedImage = () => {
    return (
      <div
        sx={{
          width: '100%',
          backgroundColor: 'muted',
          height: '60%',
          flexGrow: 0,
          overflow: 'hidden',
        }}
      >
        <img
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src={imgSrc}
          alt={imgAlt}
        />
      </div>
    );
  };

  const PromoContent = () => {
    return (
      <div
        sx={{
          flexGrow: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          '@supports (backdrop-filter: blur(8px))': {
            backdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          },
          minHeight: '40%',
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          bottom: 0,
        }}
      >
        <span sx={{ variant: 'body', p: 2, color: 'text' }}>{excerpt}</span>
      </div>
    );
  };

  return (
    <Link
      to={`/${slug}`}
      sx={{ textDecoration: 'none', width: '100%', maxWidth: '22rem' }}
    >
      <div
        className="service-box"
        onMouseEnter={(e) => {
          onMouseEnter(e);
        }}
        onMouseLeave={(e) => {
          onMouseLeave(e);
        }}
        sx={{
          width: '100%',
          height: '22rem',
          backgroundColor: 'muted',
          display: 'flex',
          flexDirection: 'column',
          border: 'solid',
          borderWidth: '1px',
          borderColor: 'text',
        }}
      >
        <TitleBox />
        <div
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundImage: `url("${imgSrc}")`,
            backgroundSize: 'cover',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <FeaturedImage />
          <PromoContent />
        </div>
      </div>
    </Link>
  );
};

ServicePromoBox.propTypes = {};

export default ServicePromoBox;
